import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PlatformService extends Platform {
  get IPAD() {
    return navigator?.platform === 'MacIntel' && navigator?.maxTouchPoints > 1;
  }

  get isTouchDevice() {
    return this.IOS || this.ANDROID || this.IPAD;
  }

  get isIosTouchDevice() {
    return this.IOS || this.IPAD;
  }

  get MAC() {
    return navigator.userAgent.includes('Mac');
  }
}
